<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Payment 2"/>

    <div class="generalBorderlessBox">
        <div class="row">
            <div class="col-12 mb-2">
                Payment success
            </div>

            <!-- <div class="col-12">
                <button class="btn btn-secondary" @click="redirect()">
                    pay $10
                </button>
            </div> -->
        </div>
    </div>
</template>

<script>
// import { faProductHunt } from '@fortawesome/free-brands-svg-icons'
import { ref, inject, onMounted } from 'vue'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import funcs from '@/functions/function'

// product method - https://www.youtube.com/watch?v=NqGEMYz89HY&t=2360s
// backend: https://www.youtube.com/watch?v=WG4ehXSEpz4
// vue front, nodejs back https://www.youtube.com/watch?v=fkEaw5kEiMc
// recurring payment - https://www.youtube.com/watch?v=GpTzyPy6PL4
// In stripe: create a product link the product app to frontend

// pakage method:
// import {loadStripe} from '@stripe/stripe-js'

export default {
    name: 'Payment2',
    components: { TopNavigation, Alert },
    setup () {

        const alert = ref([])
        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const store = useStore()
        const { getUserId } = useStore()

        const stripe = null

        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        const makePayment = (packageId) => {
            console.info('makePayment')
            if (packageId === 'basic') {
                console.info('makePayment: basic')
                window.location.href = 'https://buy.stripe.com/test_3cs9DgeAufVjaQ07st'

            } else if (packageId === 'enterprise') {
                console.info('makePayment: enterprise')
                window.location.href = 'https://buy.stripe.com/test_5kAaHkake7oN3ny6oq'
            }
        }

        const redirect = () => {
            stripe.redirectToCheckout({
                successUrl: 'http://localhost:8080/success',
                cancelUrl: 'http://localhost:8080',
                lineItems: [
                    {
                        price: 'PRODUCT_API_ID',
                        quantity: 1
                    }
                ],
                mode: 'payment'

                // unit_amount - price in cents
                /* lineItems: [
                    {
                        price_data: {
                            currency: 'usd',
                            product_data: {
                                name: 'product abc'
                            },
                            unit_amount: 100
                        }
                        quantity: 1
                    }
                ] */
            })
        }

        const checkoutToBackend = async () => {
            try {
                const res = await axios.get('/payment/checkout')
                const data = await res.json()

                window.location.href = data.url // redirect to stripe checkout page

            } catch (error) {
                func.addLog('payment', 'checkoutToBackend - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            }
        }

        onMounted(async() => {
            // package method:
            // stripe = await loadStripe('MY_STRIPE_PUBLISHABLE_KEY')
            // cdn method:
            // stripe = Stripe('MY_STRIPE_PUBLISHABLE_KEY')
        })

        return {
            route, router, alert, func, closeAlert, getUserId, 
            redirect, makePayment
        }
    }
}
</script>